import React, { createContext, ReactNode, useContext, useState } from 'react'
import { LayoutSearchContextType } from './types'

const LayoutSearchContext = createContext<LayoutSearchContextType>({
  searchQuery: '',
  setSearchQuery: () => {},
  searchCallback: () => {},
  setSearchCallback: () => {},
})

export const LayoutSearchProvider = ({ children }: { children: ReactNode }) => {
  const [searchQuery, setSearchQuery] = useState('')
  const [searchCallback, setSearchCallback] = useState<(query: string) => void>(
    () => () => {},
  )

  return (
    <LayoutSearchContext.Provider
      value={{
        searchQuery,
        setSearchQuery,
        searchCallback,
        setSearchCallback,
      }}
    >
      {children}
    </LayoutSearchContext.Provider>
  )
}

export const useLayoutSearchContext = () => useContext(LayoutSearchContext)
